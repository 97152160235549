.summary_wrapper{

    .sub-block{
        // height: 250px;

        .social-icon-block {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            padding: 15px 0 0;
            justify-content: center;
            gap: 10px;

            img{
                width: 48px;
                padding: 0 5px;
            }
        }

        // .twitter_logo{
        //     background: black;
        //     border-radius: 100%;
        //     display: flex;
        //     width: 40px;
        //     height: 40px;
        //     justify-content: center;
        //     align-items: center;

        //     .twitter{
        //         width: 75%;
        //         height: auto;
        //     }
        // }

        .content-block{
            p{
                font-weight: 300;
                font-size: 13px;
                line-height: 20px; 
            }

            .summary_form{
                display: flex;
                align-items: center;
            }

            .form-input{
                padding: 10px 0;
                display: flex;
                align-items: center;

                input {
                    border-radius: 30px;
                    height: 50px;
                    margin: 0;
                    border: none;
                    padding: 20px;
                    margin-right: 15px;
                    width: 80%;
                }

                svg{
                    color: #EE6100;
                    background: #fff;
                    border-radius: 50%;
                    font-size: 45px;
                }
            }

            .QR-section {
                display: flex;
                flex-direction: row;

                .QR-code{
                    width: 100%;

                    .clipboard_copy{
                        color: #ffffff;
                        font-style: normal;
                        font-weight: 800;
                        padding: 10px 0 0;
                        font-size: 14px;
                        line-height: 15px;
                        text-transform: uppercase;
                    }
                }
            }

            .QR-code-details{
                display: flex;
                align-items: center;

                .QR-code-text {
                    overflow: hidden;
                    text-overflow: ellipsis; 
                    white-space: nowrap; 
                    width: 100%; 
                    color: #ffffff;
                }

                
            }

            .video-block{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                border: 2px solid #575757;
                border-radius: 10px;
                height: 30vh;

                @media  screen and (max-width: 1024px){
                    background-position: center -5vh !important;
                    height: 40vh; 
                }

                img {
                    object-fit: cover;
                    max-height: 160px;
                    height: 100%;
                    width: auto;
                }
            }

            .btn_loadurlbtn{
                display: inline-block;
                padding: unset;
                border: none;
                border-radius: 0;
                outline: none;
                color: unset;
                background-color: unset;
                position: relative;
            }
        }
    }

    .summary_scroll {
        padding: 70px 15px 0;
    }

    .title-block{
        font-style: normal;
        font-weight: 800;
        font-size: 24px;
        line-height: 24px;
        color: #FFFFFF;
        width: 100%;
        padding-bottom: 20px;
    }

    .two_btn_section{
        padding: 29px 0 21px 0;
    }

    .two_btn_section, .five_btn_section {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;

        button, .button{
            width: 47%;
            margin-bottom: 10px;
            text-decoration: none;
            display: flex;
            padding: 0 !important;
            height: 45px;
            margin-bottom: 0px;
            text-decoration: none;
            justify-content: center;
            align-items: center;
        }
    }

    .five_btn_section{
        padding: 21px 0 27px 0;
        gap: 11px 0px;
    }
    
    .hr-line {
        border-top: 2px solid #575757;
    }

    img.closeicon {
        right: 12px;
        position: absolute;
    }

    .block_title_area {
        justify-content: center;
    }
}

.spinner{
    width: 100vw;
    height: 100vh;
}

.disable_scroll{
    overflow: hidden !important;
}

@media  screen and (max-width: 1024px) {
    .btn_backtoconfigure{
        display: none;
    }
}