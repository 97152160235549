.your_choice_outer_wrapper{

    .choice_block{
        font-style: normal;
        font-weight: 800;
        font-size: 24px;
        line-height: 24px;
        color: #FFFFFF;
    }

    .choice_title{
        font-size: 16px;
        text-transform: uppercase;
        padding: 30px 0 10px;
        border-bottom: 1px solid #000;
    }

    .choice_children {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-style: normal;
        font-weight: 800;
        font-size: 12px;
        line-height: 15px;
        color: #575757;
        padding: 10px 0;
        border-bottom: 1px solid #000;
        align-items: center;

        .choice_children_type{
            text-transform: uppercase;
            color: #969696;
        }

        // .display-circle{
        //     position: relative;
        //     display: inline-block;
        //     width: 20px;
        //     height: 20px;
        //     border-radius: 50%;
        //     right: 0px;
        //     background-color: red;
        //     margin: 0 5px 0 5px;
        // }
    }

    .choice_wrap{
        display: flex;
        align-items: center;
        justify-content: space-between;

        .choice_pdf{
            svg{
                height: 30px;
                width: 30px;
            }
        }
    }

    .choice_children_description{
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;

    .choice_description_title{
        display: inline-block;
        max-width: 130px;
        color: #969696;
        text-transform: uppercase;
    }

    }

    .summary_color_button{
        width: 30px;
        height: 30px;
        border-radius: 50px;
        margin: 0px 0px 0px 6px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        border: 3px solid #adb5bd;
        cursor: default;
        pointer-events: none;
    }

    .one-line {
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    
        &:not(:hover) {
          text-overflow: ellipsis;
        }
      
        &:hover,
        &:focus {
          span {
            display: inline-block;
            animation-name: scroll-text;
            animation-duration: 7s;
            animation-timing-function: linear;
            animation-delay: 0s;
            animation-iteration-count: infinite;
            animation-direction: normal;
          }
        }
      
        /* define the animation */
        @keyframes scroll-text {
          0% {
            transform: translateX(0%);
          }
          90% {
            transform: translateX(-100%);
          }
          95% {
            transform: translateX(0%);
          }
          100% {
            transform: translateX(0%);
          }
        }
      }
}

.weight_wrapper{
    .choice_children_type {
        width: 70%;
    }
    .choice_children_content {
        display: flex;
        width: 30%;
        justify-content: flex-end;
        align-items: center;
        color: #969696;
    }
}