.button_wrapper{
    position: fixed;
    left: 0;
    bottom:25px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding: 0 25px;
    opacity: 1;
    transition: all 0.5s ease;
    // transform: translate(0, 0);

    &.hide{
        opacity: 0;
        pointer-events: none;
        // transform: translate(0, 125px);
    }

    button{
        border: 3px solid rgba(255, 255, 255, 0.25);
        border-radius: 50px;
        font-size: 14px;
        margin: 0 5px;
    }

    .btn_weight{
        background-color: #111111;
        color: #ffffff;
        padding: 0;
        width: 140px;
        height: 50px;

        &:hover{
            background-color: #EE6100;
            border-color: #EE6100;
        }
    }

    .btn_summary{
        background-color: #EE6100;
        color: #ffffff;
        border-color: #EE6100;
        height: 50px;

        &:hover{
            border-color: #EE6100 !important;
        }
    }

    .btn_zoomin{
        border-radius: 50px;
        margin: 0px 5px;
        width: 50px;
        height: 50px;
        padding: 0;
    }

    .fontsize_btn{
        display: block;
        margin: 15px 0;
        background-color: #111111;
        color: #ffffff;
        border-color: #111111;
        width: 230px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px;
    }

    .btn_zoomout{
        border-radius: 50px;
        margin: 0px 5px;
        width: 50px;
        height: 50px;
        padding: 0;
    }

    .btn_accessibility{
        min-width: 180px;
    }

    .btn_done{
        background-color: #111111;
        color: #ffffff;
        border-color: #111111;
    }
    
    .accessibilty_wrapper{
        position: absolute;
        bottom: 46px;
        left: 245px;

        .btn_aaccessibility_options{
            display: block;
            margin: 15px 0;
            background-color: #111111;
            color: #ffffff;
            border-color: #111111;
            width: 230px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 5px;
            border-radius: 75px;
            font-size: 14px;
            font-style: normal;
            font-weight: 800;
        }


        svg{
            color: #EE6100;
            background: #fff;
            border-radius: 50%;
            font-size: 30px;
        }
    }

    .lower-res {
        display:none;
    }

    .desktop-button-layout {
        display: block;

        button{
            height: 50px;
        }
    }
    .more_btn{
        display:none;
    }

    .button-lower-res-wrapper {
        display: flex;

        .chat_icon {
            border-radius: 100%;
            cursor: pointer;
            border: 3px solid rgba(255, 255, 255, 0.25);
            height: 50px;
        }
    }

}

@media screen and (max-width: 1024px){

    .lower-res {
        display: block;
        width: 100%;

        .mobile-button-layout {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            width: 100%;

            button {
                width: 48%;
                margin: 2px 2px 13px 0px;
                background-color: #EE6100;
                color: #ffffff;
                border-color: #EE6100;
            }
        }
    }

    .button-lower-res-wrapper {
        display: flex;
        width: 100%;
        justify-content: space-between;
        gap: 15px;
        align-items: flex-end;
        position: relative;
        top: 5px;

        button {
            width: 50%;
            margin: 0 !important;
        }

        .chat_icon{
            display: none;
        }
    }

    .button_wrapper {
        width: calc(100vw - 50px);
        bottom: 15px;

        .btn_summary{
            height: 40px;
            border: 3px solid #EE6100 !important;
            outline: none;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .btn_weight {
            width: 50%;
            height: 40px;
            border: 3px solid #111111;
        }
        .desktop-button-layout {
            display: none;
        }
        .more_btn{
            display:block;
            width: 40px !important;

            .moreicon{
                border: 3px solid #111111 !important;
                border-radius: 100%;
                width: 40px;
            }
        }
    }

    .inactive_btn{
        opacity: 0.3;
        cursor: none;
        pointer-events: none;
    }
}

@media screen and (max-width: 481px){
    .btn_weight {
        img{
            padding: 0 !important;
        }
        .weight_icon{
            display: none;
        }
    }
}