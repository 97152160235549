.parts-config-panel{
    --panel-width: 450px;
    // --icon-wrap: calc(var(--panel-width) / 7);
    --icon-wrap: 65px;
    --icon-count: 9;
    --mt: 0.5vh;
    --ht: 60vh;
    --icons-width: calc(var(--icon-wrap) - 10px);

    @media  screen and (max-width: 1680px) {
        --icon-wrap: 55px;
    }

    @media  screen and (max-width: 1366px) {
        --icon-wrap: 50px;
    }


    
    display: grid;
    grid-template-columns: var(--icon-wrap) auto;

    color: #fff;
    top: 5vh;
    bottom: 15vh;
    width: var(--icon-wrap);
    overflow: hidden;
    position: absolute;
    transform: translate(-350px, 0) scale(0.3);
    transition: transform 0.5s ease, width 0.5s ease;
    z-index: 9;

    .d-none-desktop{
        display: none !important;
    }

    @media  screen and (max-width: 1024px) {
        // --panel-width: 100vw;
        --panel-width: calc(100vw - 50px);
        --icon-wrap: 100px;
        --icon-count: 9;
        --mt: 1vh;
        --ht: 70vh;
        --icons-width: calc(var(--icon-wrap) - 10px);

        top: 0;
        bottom: 0;

        .d-none-desktop{
            display: block !important;
        }
        .d-none-mob{
            display: none !important;
        }
    }

    &.show{
        transform: translate(25px, 0) scale(1);
    }

    .icons-wrap{
        display: flex;
        flex-direction: column;
        justify-content: center;
        // align-items: center;
        position: relative;
        z-index: 1;

        @media  screen and (max-width: 1024px) {
            &.mob-icon-wrap{
                opacity: 0;
                pointer-events: none;
                width: 1px;
            }
        }
    }
    .icons{
        // width: calc((var(--ht) - ((var(--mt) * 2) * var(--icon-count))) / var(--icon-count));
        // height: calc((var(--ht) - ((var(--mt) * 2) * var(--icon-count))) / var(--icon-count));
        margin: var(--mt) 0;
        max-width: var(--icons-width);
        max-height: var(--icons-width);

        background: #111;
        cursor: pointer;
        border-radius: 100%;
        text-align: center;
        padding: 2px;
        position: relative;

        @media  screen and (max-width: 1024px) {
            width: calc((var(--ht) - ((var(--mt) * 2) * var(--icon-count))) / var(--icon-count));
            height: calc((var(--ht) - ((var(--mt) * 2) * var(--icon-count))) / var(--icon-count));

            top: 0px;
            position: relative;
        }
    }

    &.showCat{
        width: var(--panel-width);

        @media  screen and (max-width: 1024px) {
            width: 40px;
            overflow: visible;
        }

        .categories-wrap{
            opacity: 1;
        }
    }

    .categories-wrap{
        position: relative;
        z-index: 0;
        opacity: 0;
        width: calc(var(--panel-width) - var(--icon-wrap) - 25px);
        transition: all 0.5s ease;

        @media  screen and (max-width: 1024px) {
            display: flex;
            flex-direction: column;
            left: 0;
            position: absolute;
            height: 100vh;
            // width: calc(var(--panel-width) - var(--icon-wrap) + 15px);
            // width: calc(100vw - 61px);
            // width: 100%;
            width: 1px;
            justify-content: center;
        }

        .icons-notch{
            width: calc((var(--ht) - ((var(--mt) * 2) * var(--icon-count))) / var(--icon-count));
            height: calc((var(--ht) - ((var(--mt) * 2) * var(--icon-count))) / var(--icon-count));
            max-width: var(--icons-width);
            max-height: var(--icons-width);
            
            transition: all 0.5s ease;
            background-color: #111;
            left: -50px;
            border-radius: 100%;
            position: absolute;
            z-index: -1;
            top: 43px;

            @media  screen and (max-width: 1024px) {
                display: none;
            }

            .upper, .lower{
                width: 32px;
                height: 60px;
                overflow: hidden;
                position: absolute;
                left: 20px;
            }
            .upper{
                top: -33px;
            }
            .lower{
                bottom: -33px;
                img{
                    bottom: 0;
                }
            }

            img{
                width: 75px;
                position: absolute;
            }
        }

        .categories-container{
            position: absolute;
            width: 100%;
            height: 100%;
            background: #111;
            border-radius: 25px;
            text-align: center;
            opacity: 0;
            pointer-events: none;
            transition: all 0.5s ease;
            @media  screen and (min-width: 1025px) {
                top: unset !important;
            }
            

            .innerWrap{
                position: relative;
                height: 100%;
            }

            &.active{
                opacity: 1;
                pointer-events: all;
            }

            @media  screen and (max-width: 1024px) {
                position: absolute;
                // width: 100%;
                width: 88.5vw;
                height: 50px;
                height: calc((var(--ht) - ((var(--mt) * 2) * var(--icon-count))) / var(--icon-count));
                opacity: 0;
                overflow: hidden;
                top: unset;
                bottom: 0;
                // margin: var(--mt) 0;
                // transform: translate(0, -2px);
                // transform: translate(0, -70%);


                &.align-bottom {
                    height: 45vh !important;
                    top: unset !important;
                    bottom: 0 !important;
                    position: fixed;
                    border-radius: 25px 25px 0 0;
                    transform: translate(0, -2px);

                    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#111111+0,111111+100&1+85,0+100 */
                    // background: -moz-linear-gradient(top,  rgba(17,17,17,1) 0%, rgba(17,17,17,1) 80%, rgba(17,17,17,0) 100%); /* FF3.6-15 */
                    // background: -webkit-linear-gradient(top,  rgba(17,17,17,1) 0%,rgba(17,17,17,1) 80%,rgba(17,17,17,0) 100%); /* Chrome10-25,Safari5.1-6 */
                    // background: linear-gradient(to bottom,  rgba(17,17,17,1) 0%,rgba(17,17,17,1) 80%,rgba(17,17,17,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                    // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#111111', endColorstr='#00111111',GradientType=0 ); /* IE6-9 */

                    .innerWrap{
                        position: relative;
                        height: 80%;
                    }
                }

                .innerWrap{
                    position: relative;
                    height: auto;
                }
            }

            @media  screen and (min-width: 768px) and (max-width:1024px) {
                width: 92vw;
            }
        }

        .icons_title_area {
            display: flex;
            justify-content: space-between;
            height: 50px;
            align-items: center;
            padding: 0 13px 0 22px;
            margin-bottom: 5px;

            .icon_arrow_left{
                path{
                    fill: rgba(255, 255, 255, 0.4);
                }
            }
    
            .icon_step {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 10px;
                width: 90%;
    
                .step{
                    font-style: normal;
                    font-weight: 800;
                    font-size: 14px;
                    line-height: normal;
                    text-transform: uppercase;
                    color: #EE6100;
                    width: 25%;
                }
            }
            .icon_frame {
                // flex-basis: 50%;
                // text-align: left;
                // font-style: normal;
                // font-weight: 800;
                // font-size: 14px;
                // line-height: 15px;
                // text-transform: uppercase;
                // color: #FFFFFF;
                // white-space: nowrap;
                // overflow: hidden;
                // text-overflow: ellipsis;
                // align-self: flex-end;

                font-style: normal;
                font-weight: 800;
                font-size: 14px;
                line-height: 15px;
                text-transform: uppercase;
                color: #FFFFFF;
                width: 70%;
                word-wrap: break-word;
                overflow: hidden;
                max-height: 30px;
                text-align: left;
                height: 100%;
            }
            .icon_check{
                cursor: pointer;
            }

            @media  screen and (max-width: 1024px) {
                padding: 0 13px 0 5px;
                height: calc((var(--ht) - ((var(--mt) * 2) * var(--icon-count))) / var(--icon-count));
            }
        }

        .icons_content_area{
            height: calc(100% - 90px);
            overflow-x: hidden;
            overflow-y: scroll;
            width: 105%;
            padding-right: 7px;

            @media  screen and (max-width: 1024px) {
                width: unset;
                padding-right: 0;
            }

            .accordion-item {
                position: relative;
                background: transparent;
                border: none;

                .accordion-collapse {
                    padding: 0px;
                    background: #222222;
                }

                button.accordion-button.collapsed, button.accordion-button {
                    border-left: none;
                    background: transparent;
                    font-style: normal;
                    font-weight: 800;
                    font-size: 14px;
                    line-height: 15px;
                    text-transform: uppercase;
                    color: #888888;
                    height: 40px;
                    padding: 10px 21px;

                    &:focus,
                    &:active{
                        box-shadow: none;
                    }
                }

                .i_icon{
                    position: absolute;
                    top: 5px;
                    right: 40px;
                    z-index: 999;
                    width: 25px;
                    cursor: pointer;

                    img{
                        position: relative;
                        left: 50%;
                        transform: translate(-50%, 0px);
                    }
                }

                .accordion_border{
                    border-color: #000000;
                    width: 90%;
                    margin: 0 auto;
                    opacity: 1;
                }

                .accordion-button:not(.collapsed) {
                    box-shadow: none;
                    background: #000000;
                }

                .accordion-button::after{
                    background-image: url('../../assets/images/icons_layout_images/DownArrow.svg');
                    width: 13px;
                    height: 9.6px;
                    background-repeat: no-repeat;
                    background-size: auto;
                    background-position: center;
                }
                .accordion-button:not(.collapsed)::after {
                    background-image: url('../../assets/images/icons_layout_images/DownArrowActive.svg');
                    transform: rotate(0deg);
                }
                .accordion-body{
                    color: #fff;
                    text-align: left;
                    padding: 20px;
                    background-color: #222222;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    flex-wrap: wrap;
                    gap: 20px 0px;
                    align-items: center;
                    overflow-y: auto;
                    overflow-x: hidden;

                    @media  screen and (max-width: 1024px) {
                        gap: 14px 0px;
                    }

                    @media  screen and (min-width: 768px) and (max-width:1024px) {
                        gap: 14px 10px;
                    }

                    .color-btn-section{
                        overflow: hidden;
                        border-radius: 100%;
                        border: 3px solid #adb5bd;
                        margin: 0 5px 0 !important;

                        &:hover{
                            border: 3px solid #EE6100;
                        }
                        &.active{
                            border: 3px solid #EE6100;
                        }

                        button{
                            border: none;
                        }
                    }

                    button{
                        border: 3px solid rgba(255, 255, 255, 0.25);

                        .full_width_button{
                            width: 324px;
                        }

                        .rounded_button{
                            width: 60px;
                            height: 60px;
                            border-radius: 60px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }

                        .frame_color_button{
                            width: 107px;
                            height: 40px;
                            border-radius: 50px;
                        }

                        .frame_color_rounded_button{
                            width: 40px;
                            height: 40px;
                            border-radius: 50px;
                            background: #7E7E7E;
                            border: 3px solid #EE6100;
                        }
                    }
                    .full_width_button {
                        padding: 0 10px 0 !important;
                        margin: 0px !important;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        min-height: 50px;
                        width: 340px !important;
                        line-height: normal;
                    }
                }

                .button{
                    background: #EE6100;
                    border-radius: 50px;
                    font-style: normal;
                    font-weight: 800;
                    font-size: 14px;
                    line-height: 15px;
                    display: flex;
                    align-items: center;
                    text-transform: uppercase;
                    color: #FFFFFF;
                    height: 40px;
                    text-decoration: none;
                    justify-content: center;
                    width: 100%;
                    border: none;
                }
            }

            .more_information{
                text-align: left;

                h2{
                    font-style: normal;
                    font-weight: 800;
                    font-size: 24px;
                    line-height: 24px;
                    color: #FFFFFF;
                    margin: 0;
                }
                p{
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    color: #FFFFFF;
                    padding: 18px 0;
                    margin: 0;
                }
                button{
                    width: 200px;
                    height: 40px;
                    align-items: center;
                    display: flex;
                    justify-content: center;
                    background: #EE6100;
                    border: none;
                }
            }
        }

        /* Track */
        ::-webkit-scrollbar-track {
            display: none;

            @media  screen and (max-width: 1024px) {
                -webkit-box-shadow: inset 0 0 6px #000000; 
                -webkit-border-radius: 10px;
                border-radius: 10px;
            }
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
            -webkit-border-radius: 10px;
            border-radius: 10px;
            background: #000000; 
            -webkit-box-shadow: inset 0 0 6px #000000;

            @media  screen and (max-width: 1024px) {
                -webkit-border-radius: 10px;
                border-radius: 10px;
                background: #313131; 
                -webkit-box-shadow: inset 0 0 6px #000000; 
            }
        }
    }
}




.icon_wrapper_width{
    width: 465px !important;
}
.icons_wrapper{
    // position: fixed;
    // bottom: 150px;
    // left: 3px;
    // transform-origin: bottom;
    // padding: 30px 0px 30px 10px;

    position: fixed;
    transform-origin: bottom;
    padding: 30px 0px 30px 0px;
    top: 50%;
    left: 13px;
    transform: translate(0, -50%);
    transition: all 0.25s ease;
    width: 76px;
    
    &.mobile-panel{
        @media  screen and (max-width: 1024px) {
            display: block !important;
        }
    }

    .up_button{
        position: absolute;
        color: #ccc;
        top: -20px;
        left: 29px;
        right: 0;
        transition: all 0.5s ease;

        // &.hide{
        //     cursor: default;
        //     pointer-events: none;
        //     opacity: 0.5;
        // }

        // &.show{
        //     opacity: 1;
        // }

        &.disable{
            opacity: 0;
            pointer-events: none;
        }
    }

    .down_button {
        color: #ccc;
        position: absolute;
        top: auto;
        left: 29px;
        right: 0;
        bottom: -25px;
        transition: all 0.5s ease;

        // &.hide{
        //     cursor: default;
        //     pointer-events: none;
        //     opacity: 0.5;
        // }

        // &.show{
        //     opacity: 1;
        // }

        &.disable{
            opacity: 0;
            pointer-events: none;
        }
    }

    .icons_block_wrapper{
        position: relative;
        opacity: 1;
        left: 0;
        transition: all 0.5s ease;
        transform-origin: left center;
        transform: translate(-110%, 0);
        width: 76px;

        &.show{
            opacity: 1;
            transform: translate(0, 0) scale(1);
        }
    }
    

    .grid-container {
        display: grid;
        grid-template-rows: 50px 50px 50px;
        grid-template-columns: 50px auto;
        gap: 10px 13px;
        color: #fff;
        text-align: center;
        position: relative;
        max-height: calc(100vh - 355px);
        overflow: hidden auto;
        padding: 12px 0 0 12px;

        &.null_grid{
            overflow-y: scroll;
            scrollbar-width: none; /* Firefox */
            -ms-overflow-style: none;    
        }
        &::-webkit-scrollbar{
            width: 0;
            height: 0;
        }

        .notch{
            // display: none;
            opacity: 0;
            position: absolute;
            top: 12px;
            left: 35px;
            width: 40px;
            height: 124px;
            background-image: url('../../assets/images/icons_layout_images/upper_notch.svg'), url('../../assets/images/icons_layout_images/lower_notch.svg');
            // background-position: left top, left bottom;
            background-position: 0 0px, 0 38px;
            background-repeat: no-repeat, no-repeat;
            z-index: 0;
            transition: all 0.3s ease;

            &::before {
                content: " ";
                width: 50px;
                height: 50px;
                border-radius: 100%;
                background: #111111;
                display: block;
                position: absolute;
                top: 37px;
                left: -21px;
            }

            &.notch-0{
                // display: block;
                // top: 12px;
                // background-image: url('../../assets/images/icons_layout_images/lower_notch.svg');
                
                // display: block;
                background-position: 0 -37px, 0 1px;
                opacity: 1;
                top: 12px;
                // width: 60px;

                &::before {
                    content: " ";
                    width: 50px;
                    height: 50px;
                    border-radius: 100%;
                    background: #111111;
                    display: block;
                    position: absolute;
                    top: 0;
                    left: -21px;
                }
            }
            &.notch-1{
                // display: block;
                opacity: 1;
                top: 35px;
            }
            &.notch-2{
                // display: block;
                opacity: 1;
                top: calc(35px + 60px);
            }
            &.notch-3{
                // display: block;
                opacity: 1;
                top: calc(35px + 120px);
            }
            &.notch-4{
                // display: block;
                opacity: 1;
                top: calc(35px + 180px);
            }
            &.notch-5{
                // display: block;
                opacity: 1;
                top: calc(35px + 240px);
            }
            &.notch-6{
                // display: block;
                opacity: 1;
                top: calc(35px + 300px);
            }
            &.notch-7{
                // display: block;
                opacity: 1;
                top: calc(35px + 360px);
            }
            &.notch-8{
                // display: block;
                // top: calc(35px + 440px);
                // height: 87px;
                // background-image: url('../../assets/images/icons_layout_images/upper_notch.svg');

                // display: block;
                opacity: 1;
                top: calc(35px + 420px);
                // width: 60px;
                height: 86px;
            }
        }
    }

    .icons_style {
        background: #111111;
        border-radius: 30px;
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        position: relative;
        transition: 200ms;
        box-sizing: border-box;

        .icons_effect{
            width: 100%;
            height: 100%;
            background: #111;
            border-radius: 100%;
            position: absolute;
            z-index: 2;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .mobile_wrapper{
        opacity: 0;
        pointer-events: none;
    }

    .icon_0{

        &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            border:2px solid #fff;
            border-radius: 100%;
            top: 0;
            left: 0;
            box-sizing: border-box;
            transform: scale(1);
            animation: pulse2 2.5s infinite;
            z-index: 1;
        }
        &::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            border:2px solid #fff;
            border-radius: 100%;
            top: 0;
            left: 0;
            box-sizing: border-box;
            transform: scale(1);
            animation: pulse 2.5s infinite;
            z-index: 1;
        }
        
        @keyframes pulse {
            0% {
                transform: scale(1);
                opacity: 0.7;
            }
            100% {
                transform: scale(1.4);
                opacity: 0;
            }
        }
        @keyframes pulse2 {
            0% {
                transform: scale(1);
                opacity: 0.7;
            }
            30% {
                transform: scale(1);
                opacity: 0.7;
            }
            100% {
                transform: scale(1.4);
                opacity: 0;
            }
        }
    }

    .disable_effect::before{
        border-color: transparent;
        transition: 200ms;
    }
    .disable_effect::after{
        border-color: transparent;
        transition: 200ms;
    }

    .icons_block{
        width: 382px;
        height: 530px;
        background: #111111;
        border-radius: 25px;
        position: relative;
        z-index: 2;
        overflow: hidden;
        transition: all 0.5s ease;

        &.show_block{
            opacity: 1;
            pointer-events: all;
        }

        &.hide_block{
            opacity: 0;
            pointer-events: none;
        }

        .icons_title_area {
            display: flex;
            justify-content: space-between;
            height: 50px;
            align-items: center;
            padding: 0 13px 0 22px;
            margin-bottom: 5px;
    
            .icon_step {
                display: flex;
                align-items: center;
                gap: 21.95px;
                cursor: pointer;
    
                path{
                    fill: rgba(255, 255, 255, 0.4);
                }
    
                span{
                    font-style: normal;
                    font-weight: 800;
                    font-size: 14px;
                    line-height: normal;
                    text-transform: uppercase;
                    color: #EE6100;
                }
            }
            .icon_frame {
                flex-basis: 50%;
                text-align: left;
                font-style: normal;
                font-weight: 800;
                font-size: 14px;
                line-height: 15px;
                text-transform: uppercase;
                color: #FFFFFF;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .icon_check{
                cursor: pointer;
            }
        }

        .icons_content_area{
            height: 430px;
            overflow-x: hidden;
            overflow-y: auto;

            .accordion-item {
                position: relative;
                background: transparent;
                border: none;

                .accordion-collapse {
                    padding: 5px 7px 5px 5px;
                    background: #222222;
                }

                button.accordion-button.collapsed, button.accordion-button {
                    border-left: none;
                    background: transparent;
                    font-style: normal;
                    font-weight: 800;
                    font-size: 14px;
                    line-height: 15px;
                    text-transform: uppercase;
                    color: #888888;
                    height: 40px;
                    padding: 10px 21px;

                    &:focus,
                    &:active{
                        box-shadow: none;
                    }
                }

                .i_icon{
                    position: absolute;
                    top: 5px;
                    right: 40px;
                    z-index: 999;
                    width: 25px;
                    cursor: pointer;

                    img{
                        position: relative;
                        left: 50%;
                        transform: translate(-50%, 0px);
                    }
                }

                .accordion_border{
                    border-color: #000000;
                    width: 90%;
                    margin: 0 auto;
                    opacity: 1;
                }

                .accordion-button:not(.collapsed) {
                    box-shadow: none;
                    background: #000000;
                }

                .accordion-button::after{
                    background-image: url('../../assets/images/icons_layout_images/DownArrow.svg');
                    width: 13px;
                    height: 9.6px;
                    background-repeat: no-repeat;
                    background-size: auto;
                    background-position: center;
                }
                .accordion-button:not(.collapsed)::after {
                    background-image: url('../../assets/images/icons_layout_images/DownArrowActive.svg');
                    transform: rotate(0deg);
                }
                .accordion-body{
                    color: #fff;
                    text-align: left;
                    padding: 27px 29px;
                    background-color: #222222;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    flex-wrap: wrap;
                    gap: 15px 12px;
                    align-items: center;
                    overflow-y: auto;
                    overflow-x: hidden;

                    .color-btn-section button{
                        border: 3px solid #adb5bd;

                        &:hover{
                            border: 3px solid #EE6100;
                        }
                        &.active{
                            border: 3px solid #EE6100;
                        }
                    }

                    button{
                        border: 3px solid rgba(255, 255, 255, 0.25);

                        .full_width_button{
                            width: 324px;
                        }

                        .rounded_button{
                            width: 60px;
                            height: 60px;
                            border-radius: 60px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }

                        .frame_color_button{
                            width: 107px;
                            height: 40px;
                            border-radius: 50px;
                        }

                        .frame_color_rounded_button{
                            width: 40px;
                            height: 40px;
                            border-radius: 50px;
                            background: #7E7E7E;
                            border: 3px solid #EE6100;
                        }
                    }
                    .full_width_button {
                        padding: 10px 20px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        min-height: 50px;
                        width: 350px !important;
                        line-height: normal;
                    }
                }

                .button{
                    background: #EE6100;
                    border-radius: 50px;
                    font-style: normal;
                    font-weight: 800;
                    font-size: 14px;
                    line-height: 15px;
                    display: flex;
                    align-items: center;
                    text-transform: uppercase;
                    color: #FFFFFF;
                    height: 40px;
                    text-decoration: none;
                    justify-content: center;
                    width: 100%;
                    border: none;
                }
            }

            .more_information{
                text-align: left;

                h2{
                    font-style: normal;
                    font-weight: 800;
                    font-size: 24px;
                    line-height: 24px;
                    color: #FFFFFF;
                    margin: 0;
                }
                p{
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    color: #FFFFFF;
                    padding: 18px 0;
                    margin: 0;
                }
                button{
                    width: 200px;
                    height: 40px;
                    align-items: center;
                    display: flex;
                    justify-content: center;
                    background: #EE6100;
                    border: none;
                }
            }
        }
    }
    .icon_0 {
        grid-area: 1;
    }
    .block_0, .block_1, .block_2, .block_3, .block_4, .block_5, .block_6, .block_7, .block_8 {
        grid-area: 1 / 2 / 4 / 2;
    }
    .block_0{
        border-radius: 0 25px 25px !important;
    }
    .block_8{
        border-radius: 25px 25px 0 0!important;
    }
    .icon_1 {
        grid-area: 2 / 1 / 2 / 1;
    }
    .icon_2{
        grid-area: 3 / 1 / 3 / 1;
    }
    .icon_3{
        grid-area: 4 / 1 / 4 / 1;
    }
    .icon_4{
        grid-area: 5 / 1 / 5 / 1;
    }
    .icon_5{
        grid-area: 6 / 1 / 6 / 1;
    }
    .icon_6{
        grid-area: 7 / 1 / 7 / 1;
    }
    .icon_7{
        grid-area: 8 / 1 / 8 / 1;
    }
    .icon_8{
        grid-area: 9 / 1 / 9 / 1;
    }
}

// Terms and conditions

.terms_wrapper{
    position: absolute;
    background: linear-gradient(0deg, #111111 60.97%, rgba(17, 17, 17, 0) 100%);
    border-radius: 0px 0px 25px 25px;
    height: 40px;
    // width: 382px;
    width: 100%;
    padding: 0px 30px 0;
    bottom: -140px;
    display: flex;
    transition: all 0.5s ease;
    cursor: pointer;
    z-index: 20;

    &.active{
        bottom: 0px;
        height: 210px;
        background: linear-gradient(0deg, #111111 80.97%, rgba(17, 17, 17, 0) 100%);
        padding-top: 50px;
        cursor: default;
        
        .terms_title{
            opacity: 1;
        }
    }

    .terms_block{
        display: flex;
        flex-direction: column;
        gap: 21px;
        justify-content: flex-start;
    }

    &.inactive{
        bottom: 0px;
        padding-top: 16px;
    }

    .terms_title{
        font-style: normal;
        font-weight: 800;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
        text-transform: uppercase;
        color: #FFFFFF;
        opacity: 0.5;
    }

    .terms_content{
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        color: #FFFFFF;
        max-height: 70px;
        overflow: auto;
    }

    .term_close{
        position: absolute;
        right: 13px;
        bottom: 15px;
        opacity: 0;
        pointer-events: none;

        &.active{
            opacity: 1;
            pointer-events: all;
        }
    }
}

// .block_8 .terms_wrapper {
//     border-radius: 0;
//     bottom: 0px;
//     padding-top: 16px;
//     overflow: hidden;
//     position: relative;
// }

// Accordion

.accordion_frame_color{
    display: flex;
    gap: 9px;
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;

    button{
        height: 40px;
        padding: 11px 23px;

        @media  screen and (max-width: 415px) {
            &:first-child{
                width:100%
            }
            &:nth-child(2), &:nth-child(3){
                width:48%
            }
        }

        @media  screen and (max-width: 340px) {
            width:100% !important;
        }
    }

    @media  screen and (max-width: 415px) {
        justify-content: space-between;       
    }
}

.icons-ripple{
    position: relative;
    top: 5vh;
    opacity: 0;

    @media  screen and (max-width: 1024px) {
        &.mob-effect{
            top: 0vh;
        }
    }

    &.effect_display{
        opacity: 1;
    }

    .ripple-effect{
        position: absolute;
        left: 25px;
        z-index: 0;

        &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            border:2px solid #ffffff;
            border-radius: 100%;
            top: 0;
            left: 0;
            box-sizing: border-box;
            transform: scale(1);
            animation: pulse2 2.5s infinite;
            z-index: 1;
        }
        &::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            border:2px solid #ffffff;
            border-radius: 100%;
            top: 0;
            left: 0;
            box-sizing: border-box;
            transform: scale(1);
            animation: pulse 2.5s infinite;
            z-index: 1;
        }
        
        @keyframes pulse {
            0% {
                transform: scale(1);
                opacity: 0.7;
            }
            100% {
                transform: scale(1.3);
                opacity: 0;
            }
        }
        @keyframes pulse2 {
            0% {
                transform: scale(1);
                opacity: 0.7;
            }
            30% {
                transform: scale(1);
                opacity: 0.7;
            }
            100% {
                transform: scale(1.3);
                opacity: 0;
            }
        }

        &.enable_effect::before{
            border-color: #ffffff;
            transition: 200ms;
        }
        &.enable_effect::after{
            border-color: #ffffff;
            transition: 200ms;
        }

        &.disable_effect::before{
            border-color: transparent;
            transition: 200ms;
        }
        &.disable_effect::after{
            border-color: transparent;
            transition: 200ms;
        }
    }
}

// Mobile resolution (768 and below)

@media  screen and (max-width: 1024px) {

    .icon_wrapper_width {
        width: 100% !important;
    }

    .left_panel_click_enable {
        cursor: pointer;
        pointer-events: all;
    }

    .left_panel_click_disable {
        cursor: default;
        pointer-events: none;
    }

    .icons_wrapper {
        left: 3px;
        top: 50%;
        padding: 0;

        .icons_block .icons_content_area {
            height: calc(100vh - 490px);
        }

        .icons_block_wrapper {
            position: absolute;
            transform: translate(-110%, -50%) !important;
            
            &.show{
                transform: translate(13px , -50%) !important;
            }

            .grid-container{
                max-height: calc(100vh - 350px);
            }

            .mobile_wrapper{
                opacity: 1;
                pointer-events: all;
                transition: all 0.5 ease;

                &.active_icon{
                    width: 50px;
                    transition: all 0.5s ease;
                    border-radius: 25px !important;

                    &.show{
                        width: 91vw;
                        top: 0;
                    }
                }

                .icons_title_area{
                    padding: 0 13px;
                }
            }

            .desktop_wrapper{
                opacity: 0;
                pointer-events: none;
            }

            .mobile_display{
                width: 100% !important;
                margin: 0 auto;
                height: 50vh !important;
                background: linear-gradient(180deg, rgba(17, 17, 17, 0.8968837535) 74%, rgba(65, 72, 75, 0) 95%);
                position: absolute;
                top: 190px !important;
                bottom: 0;

                .icon_check .right_arrow{
                    display: none;
                }

                &.hide{
                    display: none;
                }
            }

            .mobile_smaller_block{
                .icon_check .close_icon{
                    display: none;
                }

            }

            .mobile_hide{
                opacity: 0;
                pointer-events: none;
            }
        }

        .mobile_icons_wrapper{
            width: 100%;
            transform: translate(0px, 50%);
            height: 100vh !important;

            .mobile_grid{
                max-width: 92%;
                width: 100%;
                margin: 0 auto;
                display: block;
                position: absolute;
                bottom: 0px;
                left: 0;
                right: 0;
                max-height: unset;
                padding: 0;
            }

            &.show{
                transform: translate(0px , 60%) !important;
            }
           
        }

        .grid-container{
            
            .notch{
                display: none !important;
            }
    
            .icons_style {
                width: 50px;
                height: 50px;
                position: relative;
                z-index: 5;
    
                img {
                    width: 45%;
                }
            }

            .left_panel_click_enable {
                cursor: pointer;
                pointer-events: all;
            }

            .left_panel_click_disable {
                cursor: default;
                pointer-events: none;
            }

            .icon_0 {
                grid-area: 1 / 1 / 1 / 1;
            }
            .block_0{
                grid-area: 1 / 1 / 1 / 4;
            }
            .block_1{
                grid-area: 2 / 1 / 2 / 4;
            }
            .block_2{
                grid-area: 3 / 1 / 3 / 4;
            }
            .block_3{
                grid-area: 4 / 1 / 4 / 4;
            }
            .block_4{
                grid-area: 5 / 1 / 5 / 4;
            }
            .block_5{
                grid-area: 6 / 1 / 6 / 4;
            }
            .block_6{
                grid-area: 7 / 1 / 7 / 4;
            }
            .block_7{
                grid-area: 8 / 1 / 8 / 4;
            }
            .block_8{
                grid-area: 9 / 1 / 9 / 4;
            }

            .icons_block{
                height: 50px;
                overflow: hidden;
                width: 91vw;

                .icons_title_area{
                    height: 50px;
                }
            }
        }

        .terms_wrapper {
            display: none;
        }

    }
    .button_wrapper {
        flex-direction: column;
        align-items: center;
        padding: 0 0px;
        max-width: 92%;
        margin: 0 25px;
        left: 0;
        right: 0;
        z-index: 10;
    }

    .icon_check{
        .right_arrow, .close_icon{
            width: 100%;
            height: 100%;
        }
    }
}