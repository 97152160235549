.modal_video_wrapper{
        position: relative;
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;

    .modal_video_block{
        max-height: 75vh;
        border-radius: 30px;
        background: #fff;
        position: absolute;
        width: 52vw;
        height: auto;
        padding: 15px;
        overflow: hidden;
        display: flex;
        flex-direction: column;

        @media  screen and (max-width: 1024px){
            width: 95%;            
        }

        iframe{
            width: 100%;                   
            height: 60vh;

            @media  screen and (max-width: 1024px){
                height: 33vh;
            }
        }

        .modal_video_outer{
            overflow-x: hidden;
            overflow-y: auto;
            padding-right: 5px;
            display: flex;
            flex-direction: column;
            gap: 5px;
        }

        .modal_content{
            padding-bottom: 5px;
        }
    }

    &.modal_images_wrapper{
        position: relative;
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        right: 100px;
    
        .modal_image_block {
            max-height: 60vh;
            border-radius: 30px;
            background: #fff;
            position: absolute;
            width: 30vw;
            height: auto;
            padding: 15px;
            overflow: hidden;
            display: flex;
            flex-direction: column;

            @media  screen and (max-width: 1024px){
                width: 95%;            
            }
    
            .modal_image{
                padding: 15px 0;
                display: block;
                width: 100%;
                object-fit: contain;
                height: 260px;
            }
    
            .modal_image_outer{
                overflow-x: hidden;
                overflow-y: auto;
                padding-right: 5px;
                display: flex;
                flex-direction: column;
                gap: 5px;
            }
        }
    }

    &.modal_text_wrapper{
        position: relative;
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        right: 100px;

        .modal_text_block{
            max-height: 40vh;
            border-radius: 30px;
            background: #fff;
            position: absolute;
            width: 30vw;
            height: auto;
            padding: 15px;
            overflow: hidden;
            display: flex;
            flex-direction: column;

            @media  screen and (max-width: 1024px){
                width: 95%;            
            }
        }
        .modal_para_block{
            overflow-x: hidden;
            overflow-y: auto;
        }
    }

    .modal_content {
        display: flex;
        justify-content: space-between;

        h3{
            padding: 5px 0px 0;
            color: #000;
            font-weight: bold;
            font-size: 16px;
            text-transform: uppercase;

        }

        p{
            padding: 5px 0 0 0;
            color: #55595b;
            font-size: 14px;
            margin: 0;
        }
    }

    ::-webkit-scrollbar-button {
        height: 10px;
    }

    .closeicon{
        width: 30px;
        height: 30px;
    }

    @media  screen and (max-width: 1024px){
        top: 0;
        margin: auto;
        bottom: 0;
        left: 0;
        right: 0 !important;
        z-index: 11;
        background: rgba(0, 0, 0, 0.5);
        justify-content: center !important;      
    }
}

.hotspot_child{
    color: #fff;
    font-weight: 800;
    width: 20vw;
    position: absolute;
    left: 70px;
    top: -12px;
    transition: all 0.5s ease;
    opacity: 0;
    &.show{
        opacity: 1;
        pointer-events: all;
        left: 40px;
    }
    &.hide{
        opacity: 0;
        pointer-events: none;
    }
}

.hide-hotspots{
    display: none;
}