@import './Variables.scss';

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Public Sans";
}

html{
    height: 100%;
    min-height: -webkit-fill-available;
    
    body{
        --bg-color1: #050607;
        --bg-color2: #3c464b;
        --bg-color1-gra: 0%;
        --bg-color2-gra: 100%;

        --bg-color-left: 0px;
        --bg-color-top: 0px;


        overflow: hidden;
        height: 100%;

        background: var(--bg-color2);
        // background: -moz-radial-gradient(center, ellipse cover,  var(--bg-color1) var(--bg-color1-gra), var(--bg-color2) var(--bg-color2-gra));
        // background: -webkit-radial-gradient(center, ellipse cover,  var(--bg-color1) var(--bg-color1-gra),var(--bg-color2) var(--bg-color2-gra));
        // background: radial-gradient(ellipse at center,  var(--bg-color1) var(--bg-color1-gra),var(--bg-color2) var(--bg-color2-gra));
        // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='var(--bg-color1)', endColorstr='var(--bg-color2)',GradientType=1 );
        min-height: -webkit-fill-available;

        max-height: -webkit-fill-available;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        overflow: hidden;
    }

    .bg-overlay {
        position: fixed;
        overflow: hidden;

        &::before {
            content: " ";
            background: var(--bg-color1);
            // background: radial-gradient(ellipse at center, var(--bg-color1) 0%, var(--bg-color2) 100%);
            // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="var(--bg-color1)", endColorstr="var(--bg-color2)",GradientType=1 );

            background: -moz-radial-gradient(center, ellipse cover,  var(--bg-color1) var(--bg-color1-gra), var(--bg-color2) var(--bg-color2-gra));
            background: -webkit-radial-gradient(center, ellipse cover,  var(--bg-color1) var(--bg-color1-gra),var(--bg-color2) var(--bg-color2-gra));
            background: radial-gradient(ellipse at center,  var(--bg-color1) var(--bg-color1-gra),var(--bg-color2) var(--bg-color2-gra));
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='var(--bg-color1)', endColorstr='var(--bg-color2)',GradientType=1 );
            
            overflow: hidden;
            height: 100%;
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 150vw;
            height: 100vh;
            background-position: var(--bg-color-left) var(--bg-color-top);
            background-repeat: no-repeat;
            background-size: cover;
        }
    }
}

#leva__root{
    display: none;
}

.cursor-pointer{
    cursor: pointer;
}

#root{
    height: 100%;
    display: flex!important;
    flex-direction: column!important;
}

#three-canvas-container{
    height: 100vh !important;
}

header{
    .chaticon{
        top: 30px;
        left: 25px;
        cursor: pointer;
        position: absolute;
        border: 3px solid rgba(255, 255, 255, 0.25) !important;
        border-radius: 100%;
    }

    .chat_desktop{
        display: none;
    }

    .logo{
        top: 28px;
        left: 50%;
        transform: translate(-50%, 0);
        cursor: pointer;
        position: absolute;
        width: 120px;
        height: 60px;
    }

    .qsxlogo{
        top: 30px;
        right: 40px;
        cursor: pointer;
        position: absolute;
        width: 80px;
        height: 60px;
    }

    @media  screen and (max-width: 1024px){
        z-index: 12 !important;
    }


}

body {
    h1{
        color: #fff;
    }

    button, .button{
        padding: 14px 25px;
        border: 2px solid #575757;
        border-radius: 50px;
        outline: none;
        color: #fff;
        background-color: #212527;
        text-align: center;
        // transition: all 0.5s ease;
        transition: background-color 0.75s ease;
        cursor: pointer;
        font-style: normal;
        font-weight: 800;
        font-size: 14px;
        line-height: 15px;
        text-transform: uppercase;
        opacity: 1;

        &:hover{
            color: #fff;
            background-color: #EE6100;
            border-color: #EE6100 !important;
        }

        &.active{
            color: #fff;
            background-color: #EE6100;
            border-color: #EE6100 !important;
        }

        &.hide{
            opacity: 0;
            pointer-events: none;
        }

        &:hover{
            @media  screen and (max-width: 1024px){
                color: unset;
                background-color: #212527;
                border-color: rgba(255, 255, 255, 0.25) !important;
            }
        }

        &.active:hover{
            @media  screen and (max-width: 1024px){
                color: #fff;
                background-color: #EE6100;
                border-color: #EE6100 !important;
            }
        }
    }   
}
// .custom_active.active{
//     transition: background-color 10s ease;
// }

.nitrum-layout{
    position: absolute;
    width: 100%;
    height: 50px;
    bottom: 0;
    z-index: 99999;

    .button-position{
        right: 3%;
        position: absolute;
        bottom: 30%;
    }

    .hide{
        display: none;
    }
}

.touchpoint_disabled{
    pointer-events: none;
}

.nitrum-button{
    color: #fff;
    background-color: #f5a70f;
    border-color: #f5a70f;
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 10px 20px;
    outline: none;
    text-align: center;
    font-weight: 700;
    border-radius: 24px;
    text-decoration: none;

    &:hover{
        color: #ffffff;
    }
}
.text-orange{
    color: #EE6100;
}

.font-size-14{
    font-size: 14px;
}
.font-size-24{
    font-size: 24px;
}

.font-size-30{
    font-size: 30px;
}

.font-weight-800{
    font-weight: 800;
}

.d-none{
    display: none;
}

.d-block{
    display: block;
}

.higher-res-d-none{
    display: none;
}

.higher-res-d-block{
    display: block;
}

.lower-res-d-none{
    display: none;
}

.lower-res-d-block{
    display: block;
}

//scrollbar

::-webkit-scrollbar {
    width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #000000; 
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #313131; 
    -webkit-box-shadow: inset 0 0 6px #000000; 
}
::-webkit-scrollbar-thumb:window-inactive {
    background: #111111; 
}

// ::-webkit-scrollbar-button {
//     height: 20px;
//   }

@-moz-document url-prefix() {
    .moz_scroll {
        // overflow: hidden auto;
        scrollbar-color: #313131 #111111;
        scrollbar-width: thin;
    }
    .scroll_width_none {
        scrollbar-width: none !important;
    }
    .scroll::-webkit-scrollbar {
        width: 0.5em;
        background-color: #111111;
    }
    .scroll::-webkit-scrollbar-thumb {
        background-color: #313131;
    }
}

@media  screen and (max-width: 1024px) {
      
    header{
        .chaticon{
            top: 15px;
            left: 25px;
            width: 45px;
        }
        .chat_desktop{
            display: block;
        }
        
        .qsxlogo{
            top: 10px;
            width: 70px;
            right: 27px;
        }

        .logo {
            top: 6px;
            width: 24%;
        }
    }

}

.b-hide{
    display: none !important;
}

.color-btn-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 9px;

    .rounded_button{
        width: 40px;
        height: 40px;
        border-radius: 50px;
        margin: 0px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
    }
}

.loading-inner{
    animation:spin 4s linear infinite;
}

@keyframes spin { 100% {transform:rotate(360deg); } }

.hide_loader{
    pointer-events: none;
}

#overlay {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #212121;
    z-index: 99999999;

    #overlay_image{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        -ms-transform: translate(-50%,-50%);
        width: 100%;

        img{
            width: 100%;
        }
    }
}

// @media screen and (max-width: 1025px) and (max-height: 769px) and (orientation: landscape) {
//     #overlay {
//         display: block;        
//     }  
// }

// @media screen and (max-width: 897px) and (max-height: 415px) and (orientation: landscape) {
//     #overlay {
//         display: block;        
//     }  
// }

@media screen and (orientation:landscape) and
(min-device-width: 200px) and (max-device-width: 1024px) {
    #overlay {
        display: block;        
    }  
}

// Chaticon

div.widget-visible {
    position: fixed !important;
    right: 0 !important;
    transform: translate(-290px, 0px) !important;
    opacity: 0 !important;
}