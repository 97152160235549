.weight_wrapper{
    overflow: auto;
    width: 105%;
    height: 82vh;
    padding-right: 7px;

    .total-weight-block{
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        margin-bottom: 25px;
        padding-bottom: 25px;
        font-size: 24px;
        font-weight: 800;
        line-height: 15px;
    }

    .total-weight-description{    

        p{
            font-weight: 300;
            font-size: 12px;
            line-height: 20px; 
        }
    }

    .block_title_area {
        padding: 0 15px;
        justify-content: space-between;
    }

    .choice_children_type{
        color: #969696;
    }

    .weight_panel_block{
        padding: 70px 15px 0;
    }

    @media screen and (max-width: 1024px){
        overflow: unset;
        width: unset;
        height: unset;
        padding-right: unset;
    }
}