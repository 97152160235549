.main_block{
    max-width: 92%;
    width: 392px;
    height: 83vh;
    background: #111111;
    border-radius: 25px;
    position: absolute;
    right: 50px;
    bottom: 25px;
    color: #fff;
    overflow: unset;
    z-index: 999;
    pointer-events: none;
    padding-top: 5px;

    opacity: 0;
    transform: scale(0);
    transform-origin: bottom right;
    transition: all 0.5s ease;

    &.show{
        opacity: 1;
        transform: scale(1);
        pointer-events: all;
    }

    .summary_wrapper{
        overflow: auto;
        width: 105%;
        height: 82vh;
        padding-right: 7px;
    }   

    .block_title_area {
        display: flex;
        height: 50px;
        align-items: center;
        font-style: normal;
        font-weight: 800;
        font-size: 14px;
        line-height: 15px;
        text-transform: uppercase;
        color: #FFFFFF;
        width: 100%;
        position: fixed;
        background: linear-gradient(0deg, rgba(17,17,17,0.09287464985994398) 1%, rgba(17,17,17,1) 30%);
        top: 0px;
        z-index: 9;
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        display: none;

        @media  screen and (max-width: 1024px) {
            -webkit-box-shadow: inset 0 0 6px #000000; 
            -webkit-border-radius: 10px;
            border-radius: 10px;
        }
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        -webkit-border-radius: 10px;
        border-radius: 10px;
        background: #000000; 
        -webkit-box-shadow: inset 0 0 6px #000000;

        @media  screen and (max-width: 1024px) {
            -webkit-border-radius: 10px;
            border-radius: 10px;
            background: #313131; 
            -webkit-box-shadow: inset 0 0 6px #000000; 
        }
    }

}

.main_block::-webkit-scrollbar-button {
    height: 17px;
}

@media screen and (max-width: 1024px){
    .main_block {
        max-width: 92%;
        width: unset;
        right: 0;
        left: 0;
        height: 50vh;
        margin: auto;
        bottom: 0;
        padding: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        transform-origin: bottom;
        transform: scale(1);
        transition: all 0.4s ease;
        overflow: auto;

        .summary_wrapper{
            overflow: unset;
            width: unset;
            height: unset;
            padding-right: unset;
        }  
    }
}