/* public-sans-300 - vietnamese_latin-ext_latin */
@font-face {
font-family: 'Public Sans';
font-style: normal;
font-weight: 300;
src: url('./public-sans-v14-vietnamese_latin-ext_latin-300.eot'); /* IE9 Compat Modes */
src: local(''),
        url('./public-sans-v14-vietnamese_latin-ext_latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('./public-sans-v14-vietnamese_latin-ext_latin-300.woff2') format('woff2'), /* Super Modern Browsers */
        url('./public-sans-v14-vietnamese_latin-ext_latin-300.woff') format('woff'), /* Modern Browsers */
        url('./public-sans-v14-vietnamese_latin-ext_latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
        url('./public-sans-v14-vietnamese_latin-ext_latin-300.svg#PublicSans') format('svg'); /* Legacy iOS */
}
/* public-sans-regular - vietnamese_latin-ext_latin */
@font-face {
font-family: 'Public Sans';
font-style: normal;
font-weight: 400;
src: url('./public-sans-v14-vietnamese_latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
src: local(''),
        url('./public-sans-v14-vietnamese_latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('./public-sans-v14-vietnamese_latin-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
        url('./public-sans-v14-vietnamese_latin-ext_latin-regular.woff') format('woff'), /* Modern Browsers */
        url('./public-sans-v14-vietnamese_latin-ext_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
        url('./public-sans-v14-vietnamese_latin-ext_latin-regular.svg#PublicSans') format('svg'); /* Legacy iOS */
}
/* public-sans-500 - vietnamese_latin-ext_latin */
@font-face {
font-family: 'Public Sans';
font-style: normal;
font-weight: 500;
src: url('./public-sans-v14-vietnamese_latin-ext_latin-500.eot'); /* IE9 Compat Modes */
src: local(''),
        url('./public-sans-v14-vietnamese_latin-ext_latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('./public-sans-v14-vietnamese_latin-ext_latin-500.woff2') format('woff2'), /* Super Modern Browsers */
        url('./public-sans-v14-vietnamese_latin-ext_latin-500.woff') format('woff'), /* Modern Browsers */
        url('./public-sans-v14-vietnamese_latin-ext_latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
        url('./public-sans-v14-vietnamese_latin-ext_latin-500.svg#PublicSans') format('svg'); /* Legacy iOS */
}
/* public-sans-600 - vietnamese_latin-ext_latin */
@font-face {
font-family: 'Public Sans';
font-style: normal;
font-weight: 600;
src: url('./public-sans-v14-vietnamese_latin-ext_latin-600.eot'); /* IE9 Compat Modes */
src: local(''),
        url('./public-sans-v14-vietnamese_latin-ext_latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('./public-sans-v14-vietnamese_latin-ext_latin-600.woff2') format('woff2'), /* Super Modern Browsers */
        url('./public-sans-v14-vietnamese_latin-ext_latin-600.woff') format('woff'), /* Modern Browsers */
        url('./public-sans-v14-vietnamese_latin-ext_latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
        url('./public-sans-v14-vietnamese_latin-ext_latin-600.svg#PublicSans') format('svg'); /* Legacy iOS */
}
/* public-sans-700 - vietnamese_latin-ext_latin */
@font-face {
font-family: 'Public Sans';
font-style: normal;
font-weight: 700;
src: url('./public-sans-v14-vietnamese_latin-ext_latin-700.eot'); /* IE9 Compat Modes */
src: local(''),
        url('./public-sans-v14-vietnamese_latin-ext_latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('./public-sans-v14-vietnamese_latin-ext_latin-700.woff2') format('woff2'), /* Super Modern Browsers */
        url('./public-sans-v14-vietnamese_latin-ext_latin-700.woff') format('woff'), /* Modern Browsers */
        url('./public-sans-v14-vietnamese_latin-ext_latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
        url('./public-sans-v14-vietnamese_latin-ext_latin-700.svg#PublicSans') format('svg'); /* Legacy iOS */
}