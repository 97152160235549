.intro_wrapper{
    // color: var(--main-text-color);
    // text-align: center;
    // gap: 40px;
    // position: fixed;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    // display: flex;
    // flex-direction: column;
    
    // width: 100%;
    // padding: 0 25px;

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #111111;

    .inner_wrapper{
        max-width: 550px;

        color: var(--main-text-color);
        text-align: center;
        gap: 40px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        
        width: 100%;
        padding: 0 25px;
    }

    .intro_text_block{
        display: flex;
        flex-direction: column;
        gap: 47.4px;
    }

    .intro_title, .loader_title{
        font-style: normal;
        font-weight: 800;
        font-size: 38.4px;
        line-height: 14px;
        text-align: center;
    }
    .intro_info, .loader_info{
        font-style: normal;
        font-weight: 400;
        font-size: 17.28px;
        line-height: 23px;
        text-align: center;
    }
    .button{
        border-radius: 48px;
        font-style: normal;
        font-weight: 800;
        font-size: 13.44px;
        line-height: 14px;
        font-size: 14px;
        line-height: 15px;
        text-align: center;
        text-transform: uppercase;
        width: 238.08px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--main-text-color);
        text-decoration: none;
        cursor: pointer;
    }
    .basic_button{
        background: var(--intro-button-background-color);

        &:hover,
        &:focus,
        &:active{
            background: var(--intro-button-background-color);
        }
    }
    .transparent_button{
        background: transparent;
        border: 3px solid rgba(255, 255, 255, 0.4);

        &:hover,
        &:focus,
        &:active{
            background: transparent;
        }
    }
    .intro_button_block{
        display: flex;
        flex-direction: column;
        gap: 21.6px;
        align-items: center;
    }
}
.loader_image{
    position: relative;
}
.loader_logo{
    img{
        width: 133px;
        height: 37px;
    }
}
.loader-circle{
    border-radius: 50%;
    left: 40%;
    height: 78px;
    display: flex;
    width: 78px;
    background: #111111;
    row-gap: 30px;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 20%;
}
.circular-progress{
    --progress-deg: 3.6deg;

    position: relative;
    height: 78px;
    width: 78px;
    border-radius: 50%;
    background: conic-gradient(#EE6100 var(--progress-deg), #414141 0deg);
    display: flex;
    align-items: center;
    justify-content: center;
}
.circular-progress::before{
    content: "";
    position: absolute;
    height: 58px;
    width: 58px;
    border-radius: 50%;
    background-color: #111111;
}
.progress-value{
    position: relative;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 14px;
    text-align: center;
    color: #FFFFFF;
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {  }

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {  }

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {  }

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) { 
    .intro_info span{
        display: block;
    }
    .intro_title, .loader_title{
        font-weight: 800;
        font-size: 38.4px;
        line-height: 14px;
    }
    .intro_info, .loader_info{
        font-weight: 400;
        font-size: 17.28px;
        line-height: 23px;
    }
    .button{
        font-size: 13.44px;
        line-height: 14px;
    }
}
